import axios from 'axios'
import { useState } from "react"
import { GET_NIK_URL } from '../config/api'

import { PictureControls } from "./PictureControls"
import { useDispatch, useSelector } from "react-redux"
import { getAuthError, getRequest, getScreenshot, registerPeserta, setAuthError, setRegisterNik, setActiveTab, resetAuthRegisterState, setCameraStatus } from "../features/auth/authSlice"
import { getFaces } from "../features/auth/facenetSlice";
import { Alert } from "./Alert";

export const Register = ({ enableInput }) => {

    // const isFirefox = typeof InstallTrigger !== 'undefined';

    const dispatch = useDispatch()
    const request = useSelector(getRequest)
    // const name = request.register.name
    // const email = request.register.email
    const nik = request.register.nik
    const screenshot = useSelector(getScreenshot)
    const error = useSelector(getAuthError)
    const faces = useSelector(getFaces)

    const [getDataByNik, setDataByNik] = useState({ status: false, data: { nama: null, nik: null } });
    const [getDataByNikError, setDataByNikError] = useState(null);

    const manageForm = (e) => {
        dispatch(setActiveTab('register'))
        e.preventDefault()
        validateInputs()
        if (
            error.register.nik == null &&
            screenshot != null &&
            faces.length !== 0
        ) {
            setDataByNik({ status: false, data: { nama: null, nik: null } });
            setDataByNikError(null);

            const user = { nik, screenshot, descriptor: Object.values(faces[0].descriptor) }
            dispatch(registerPeserta(user)).then(payload => {
                if (payload.meta.requestStatus === 'fulfilled') {
                    setTimeout(() => {
                        setDataByNik({ status: false, data: { nama: null, nik: null } })
                        setDataByNikError(null)
                        dispatch(resetAuthRegisterState())
                    }, 5000)
                    // <Navigate to="/dashboard" />
                }
            })
        }
    }

    const validateInputs = () => {
        if (nik == null) {
            dispatch(setAuthError({ register: { nik: 'The NIK field is required.' } }))
        } else {
            dispatch(setAuthError({ register: { nik: null } }))
        }

        if (screenshot == null) {
            dispatch(setAuthError({ register: { screenshot: 'A face IMAGE is required.' } }))
        } else {
            dispatch(setAuthError({ register: { screenshot: null } }))
        }
    }

    const checkNik = () => {
        setDataByNik({ status: false, data: { nama: null, nik: null } })
        setDataByNikError(null)
        setDataByNik({ status: false, data: { nama: null, nik: null } })
        axios.post(GET_NIK_URL, {
            nik
        }).then((response) => {
            dispatch(setCameraStatus('opened'))
            setDataByNik(response.data)
        }).catch(function (error) {
            setDataByNikError(error.response.data);
        });
    }

    return (
        <>
            <div className="container">
                <div className="col-12">
                    <div className="text-center fs-1 mb-3">
                        Register Face
                    </div>
                    <Alert />
                    {/* <form> */}
                    <div className="mb-3">
                        <label className="form-label">Masukkan NIK</label>
                        <input
                            type="number"
                            name="login-nik"
                            id="login-nik"
                            className="form-control"
                            placeholder="NIK"
                            // disabled={isFirefox ? false : true}
                            value={nik || ''}
                            onChange={(e) => dispatch(setRegisterNik(e.target.value))}
                        />
                    </div>
                    <div className="mb-3">
                        {/* <button
                                type="button"
                                className="btn btn-success mb-5"
                                onClick={(e) => manageForm(e)}
                            >Check Data</button> */}
                        {/* <button type="button" onClick={() => checkNik()} className="btn btn-success mb-5" data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                        <button type="button" onClick={() => checkNik()} className="btn btn-success">
                            Check Data
                        </button>
                    </div>
                    {
                        (getDataByNik.status === true) ?
                            <></>
                            :
                            <>
                                <p className='text-danger text-center' style={{ fontWeight: 'bold' }}>{getDataByNikError}</p>
                                <div style={{ marginBottom: 250 }}></div>
                            </>
                    }

                    {
                        (getDataByNik.status === true) ?
                            <>
                                <div className="mb-3">

                                    <table className='table text-black' style={{ fontWeight: 'bold' }}>
                                        <tr>
                                            <td width={50}>NIK</td>
                                            <td width={5}>:</td>
                                            <td>{getDataByNik.data.nik}</td>
                                        </tr>
                                        <tr>
                                            <td>NAMA</td>
                                            <td>:</td>
                                            <td>{getDataByNik.data.nama}</td>
                                        </tr>
                                    </table>

                                </div>
                                <hr className="dropdown-divider"></hr>

                                <div style={{ marginTop: '50px' }}>
                                    <PictureControls />
                                    <label className="form-label text-secondary">Klik Untuk Presensi</label>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-lg btn-block mb-5"
                                        onClick={(e) => manageForm(e)}
                                    >Register Face</button>
                                </div>
                            </>
                            :
                            <></>
                    }

                    {/* </form> */}
                </div>
            </div>
        </>
    )
}