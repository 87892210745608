import { Link } from "react-router-dom";

import { CameraPresensi } from "../components/CameraPresensi";
import { Loader } from "../components/Loader";
import { Register } from "../components/Register";

export const RegisterPage = () => {

    return (
        <main>
            <Loader />
            <div className="container">

                <div className="row justify-content-center">

                    <div className="col-xl-12 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 bg-form-image">
                                        {/* fdsgasd */}
                                        <CameraPresensi />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="pt-5 pb-5">
                                            <Register />
                                        </div>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <footer className="mt-auto text-white-50">
                    <p>
                        {/* 👈Go to <Link to="/presensi" className="text-white">Presensi</Link> */}
                        👈Go to <a href="./presensi" className="text-white">Presensi</a>
                    </p>
                </footer>

            </div>
        </main>
    )
}