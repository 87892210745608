// const BACKEND_URL = 'http://localhost:3001/api'
const BACKEND_URL = 'https://identiface.bppptegal.id/api'

// BASE AUTH
const AUTH_URL = '/auth'
// BASE FACENET
const IMAGE_URL = '/image'
// BASE DATA
const DATA_URL = '/data'


export const REGISTER_URL = BACKEND_URL + '/register-face'
export const PRESENSI_URL = BACKEND_URL + '/presensi-face'
export const LOGIN_URL = BACKEND_URL + AUTH_URL + '/login'
export const LOGOUT_URL = BACKEND_URL + AUTH_URL + '/logout'

export const GET_IMAGE_URL = BACKEND_URL + IMAGE_URL + '/get/from/url'
export const GET_PROFILE_PIC = BACKEND_URL + IMAGE_URL + '/get/profile/pic'
export const GET_SIMILARITY_URL = BACKEND_URL + IMAGE_URL + '/get/matches'

export const GET_NIK_URL = BACKEND_URL + '/check-data-by-nik'

export const GET_DATA_PESERTA_URL = BACKEND_URL + DATA_URL + '/peserta'