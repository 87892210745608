import { PictureControls } from "./PictureControls"
import { useDispatch, useSelector } from "react-redux"
import { getScreenshot, presensiPeserta, setAuthError, setActiveTab, resetAuthState } from "../features/auth/authSlice"
import { getFaces } from "../features/auth/facenetSlice";
import { Alert } from "./Alert";

export const Presensi = ({ enableInput }) => {

    // const isFirefox = typeof InstallTrigger !== 'undefined';

    const dispatch = useDispatch()
    const screenshot = useSelector(getScreenshot)
    const faces = useSelector(getFaces)

    const manageForm = (e) => {
        dispatch(setActiveTab('presensi'))
        e.preventDefault()
        validateInputs()
        if (
            screenshot != null &&
            faces.length !== 0
        ) {
            const user = { screenshot, descriptor: Object.values(faces[0].descriptor) }
            dispatch(presensiPeserta(user)).then(payload => {
                if (payload.meta.requestStatus === 'fulfilled') {
                    setTimeout(() => {
                        dispatch(resetAuthState())
                    }, 5000)
                    // <Navigate to="/dashboard" />
                }
            })
        }
    }

    const validateInputs = () => {
        if (screenshot == null) {
            dispatch(setAuthError({ presensi: { screenshot: 'A face IMAGE is required.' } }))
        } else {
            dispatch(setAuthError({ presensi: { screenshot: null } }))
        }
    }

    return (
        <div className="container">
            <div className="col-12">
                <div className="text-center fs-1 mb-3">
                    Presensi Face
                </div>
                <Alert />
                <form>
                    <div style={{ marginTop: '100px' }}>
                        <PictureControls />
                        <label className="form-label text-secondary">Klik Untuk Presensi</label>
                        <button
                            type="button"
                            className="btn btn-success btn-lg btn-block mb-5"
                            onClick={(e) => manageForm(e)}
                        >Check-In</button>
                    </div>

                </form>
            </div>
        </div>
    )
}