import './App.css';
import { useEffect } from "react"
import { RegisterPage } from "./pages/Register"
import { PresensiPage } from "./pages/Presensi"
import { Route, Routes } from "react-router-dom"

function App() {
  useEffect(() => {
    // Update the document title using the browser API
    // document.getElementsByTagName("body").className = "bg-gradient-primary";
    document.body.classList.add("bg-gradient-primary");
  });
  return (
    <>
      <img className="m-3 d-none d-xl-block" style={{ float: 'left' }} alt="" src="https://kkp.go.id/assets/brand/logo.png" width={80} />
      <Routes>
        <Route path='/' element={<RegisterPage />} />
        <Route path='/presensi' element={<PresensiPage />} />
      </Routes>
    </>
  );
}

export default App;
